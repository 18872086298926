<template>
  <section>
    <section class="form">
      <h3 class="form__header">
        Timeslots
        <div class="form__header_part">
          <BaseButton
            class="button button--xxs button--outline button--uppercase"
            @click="goToPreviousWeek"
            :disabled="currentWeekIndex === 0"
            text="Previous Week"
          ></BaseButton>
          <BaseButton
            class="button button--xxs button--outline button--uppercase"
            @click="goToNextWeek"
            :disabled="currentWeekIndex >= maxWeekIndex"
            text="Next Week"
          ></BaseButton>
        </div>
      </h3>
      <div class="timeslots-calendar">
        <div class="timeslots-calendar__grid">
          <div v-for="day in currentWeekData" :key="day.date" class="timeslots-calendar__column">
            <button
              :class="`timeslots-calendar__column-item ${
                !day.isVisible && 'timeslots-calendar__column-item--disabled'
              } ${
                selectedSlot.date === day.date &&
                !selectedSlot.time &&
                'timeslots-calendar__column-item--selected'
              }`"
              :disabled="!day.isVisible"
              @click="onTimeSlotClick(day.date, '')"
            >
              <h3>{{ day.date ? $moment(day.date).format("MMM") : "" }}</h3>
              <p>{{ day.date ? $moment(day.date).format("DD") : "N/A" }}</p>
            </button>
            <button
              v-for="time in day.times"
              :key="time.value"
              class="timeslots-calendar__column-item"
              :class="{
                'timeslots-calendar__column-item--selected':
                  (selectedSlot.date === day.date && selectedSlot.time === time.value) ||
                  (selectedSlot.date === day.date && !selectedSlot.time),
                'timeslots-calendar__column-item--disabled': !time.selected || day.disabled,
              }"
              :disabled="!time.selected || day.disabled"
              @click="onTimeSlotClick(day.date, time.value)"
            >
              <p>
                {{ $moment(time.value, "HH:mm").format("ha") }}
              </p>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h3 class="form__header">RSVP List</h3>
      <Table
        className="simple"
        :loading="false"
        :items="list"
        :headerItems="headerItems"
        :tableData="tableData"
        :hasMorePages="false"
        @clickAction="clickAction"
        @sortTable="sortTable"
      ></Table>
    </section>
    <EditReservation
      v-if="modal"
      :modal="modal"
      :entityId="id"
      :item="item"
      @hideModal="hideModal"
      @updated="updated"
      @remove="deleteReservation"
    ></EditReservation>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Table from "@/components/Table.vue";
import EditReservation from "./EditReservation.vue";

export default {
  props: {
    id: Number,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      modal: false,
      allDays: [],
      selectedSlot: { date: "", time: "" },
      currentWeekIndex: 0,
      tableData: {
        sort: "name",
        order: "asc",
      },
    };
  },
  components: {
    BaseButton,
    Table,
    EditReservation,
  },
  async created() {
    this.createTimeSlots();
    await this.apiGetExhibitionRsvpList({ id: this.id });
  },
  watch: {
    items(newVal) {
      this.createTimeSlots();
    },
  },
  computed: {
    ...mapGetters("exhibitions", {
      list: "getRsvpList",
      headerItems: "getRsvpListHeaders",
      possibleTimes: "getPossibleTimes",
      item: "getRsvp",
    }),
    maxWeekIndex() {
      return Math.ceil(this.allDays.length / 7) - 1;
    },
    currentWeekData() {
      const startIndex = this.currentWeekIndex * 7;
      return this.allDays.slice(startIndex, startIndex + 7);
    },
  },
  methods: {
    ...mapActions("exhibitions", [
      "apiGetExhibitionRsvpList",
      "apiGetRsvp",
      "apiPutRsvpCheck",
      "apiDeleteRsvp",
    ]),
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetRsvp({ entity: this.id, rsvp: id });
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "checked_in": {
          let result = await this.apiPutRsvpCheck({ entity: this.id, rsvp: id });
          if (result) {
            this.updated();
          }
          break;
        }
      }
    },

    createTimeSlots() {
      const start = moment(this.startDate);
      const end = moment(this.endDate);
      const timeSlots = [];

      let currentDate = start.clone();

      while (currentDate <= end) {
        const formattedDate = currentDate.format("YYYY-MM-DD");

        const daySlots = this.possibleTimes.map(time => ({
          value: time.value,
          selected: time.selected,
        }));

        const responseDay = this.items.find(item => item.date === formattedDate);

        if (responseDay) {
          responseDay.times.forEach((timeSlot, index) => {
            if (daySlots[index]) {
              daySlots[index].selected = timeSlot.selected;
            }
          });
        }

        timeSlots.push({
          isVisible: responseDay ? true : false,
          date: formattedDate,
          times: daySlots,
        });

        currentDate.add(1, "days");
      }
      this.allDays = timeSlots;
    },
    goToPreviousWeek() {
      if (this.currentWeekIndex > 0) {
        this.currentWeekIndex--;
      }
    },
    goToNextWeek() {
      if (this.currentWeekIndex < this.maxWeekIndex) {
        this.currentWeekIndex++;
      } else {
        return;
      }
    },
    async updated() {
      let data = { ...this.tableData, id: this.id };
      let success = await this.apiGetExhibitionRsvpList(data);
      if (success) {
        this.tableData = data;
      }
    },
    async deleteReservation() {
      let data = { ...this.tableData, id: this.id };
      let result = await this.apiDeleteRsvp({ entity: this.id, rsvp: this.item.id });
      if (result) {
        this.modal = false;
        let success = await this.apiGetExhibitionRsvpList(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, sort, order, id: this.id };
      let success = await this.apiGetExhibitionRsvpList(data);
      if (success) {
        this.tableData = data;
      }
    },
    async onTimeSlotClick(date, time) {
      if (date === this.selectedSlot.date && time == this.selectedSlot.time) {
        this.selectedSlot = { date: "", time: "" };
        let data = { ...this.tableData, date: "", time: "", id: this.id };
        let success = await this.apiGetExhibitionRsvpList(data);
        if (success) {
          this.tableData = data;
        }
      } else if (date || (data && time)) {
        this.selectedSlot = {
          date,
          time,
        };
        let data = { ...this.tableData, date, time, id: this.id };
        let success = await this.apiGetExhibitionRsvpList(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    hideModal() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeslots-calendar {
  display: flex;
  flex-direction: column;
  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & > div {
      margin-bottom: 7px;
    }
    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 18px 8px;
      border-radius: 12px;
      border: 1px solid #707070;
      background: $white;
      h3 {
        @include type($fs: 16px, $lh: 1.25, $fw: 700);
        color: $black;
      }
      p {
        @include type($fs: 14px, $lh: 1.25, $fw: 600);
        color: $black;
      }
      &--selected {
        border-color: #45755a;
        background: #45755a4d;
      }
      &--disabled {
        border-color: #d3d3d3;
        &.timeslots-calendar__column-item--selected {
          background: #45755a1c;
          p {
            color: #828985;
          }
        }
        h3,
        p {
          color: #d3d3d3;
        }
      }
    }
    & > button {
      &:not(:disabled):hover {
        border-color: #45755a;
        background: #45755a4d;
      }
    }
  }
}
</style>

